
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { firebaseErrors } from "../common/methods/firebaseErrors";
import googleSignIn from "../common/methods/googleSignin";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "@firebase/auth";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const loading = ref(false);

    const submitButton = ref<HTMLElement | null>(null);

    const registration = Yup.object().shape({
      name: Yup.string().required().label("First Name"),
      surname: Yup.string().required().label("Second Name"),
      email: Yup.string().min(6).required().email().label("Email"),
      password: Yup.string().min(6).required().label("Password"),
      cpassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const onSubmitRegister = (values) => {
      loading.value = true;
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      const auth = getAuth();
      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredentials) => {
          // Signed in successfully
          const user = {
            name: `${values.name} ${values.surname}`,
            email: userCredentials.user.email,
            photoURL: userCredentials.user.photoURL,
          };
          store.dispatch("UserModule/userSignedIn", user);
          updateProfile(userCredentials.user, {
            displayName: `${values.name} ${values.surname}`,
          })
            .then(() => {
              router.push({ name: "dashboard" });
            })
            .catch((error) => {
              loading.value = false;
              console.error(error, "error updating profile");
            });
        })
        .catch((error) => {
          //Deactivate indicator
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          firebaseErrors(error.code);
        });
    };

    return {
      loading,
      registration,
      onSubmitRegister,
      submitButton,
      googleSignIn,
    };
  },
});
